var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "h-full" },
      [
        _vm.isSideBar
          ? _c(
              "div",
              {
                staticClass:
                  "customizer-header mt-6 flex items-center justify-between px-6",
              },
              [
                _c("div", [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("marketing_place.purchase_summary"))),
                  ]),
                ]),
                _vm.isSideBar
                  ? _c("feather-icon", {
                      staticClass: "cursor-pointer",
                      attrs: { icon: "XIcon" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeSideBar()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c("vs-divider", { staticClass: "mb-0" }),
        _c(
          _vm.scrollbarTag,
          {
            tag: "component",
            staticClass: "scroll-area--customizer pt-4 pb-6",
            attrs: { settings: _vm.settings },
          },
          [
            _c(
              "div",
              { staticClass: "px-6" },
              [
                !_vm.cartItems.length
                  ? _c(
                      "div",
                      { staticClass: "flex items-center justify-center pt-4" },
                      [
                        _c("p", { staticClass: "pb-4" }, [
                          _vm._v(
                            _vm._s(_vm.$t("marketing_place.purchase_is_empty"))
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.cartItems, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "mt-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-start justify-between" },
                        [
                          _c("h5", { staticClass: "truncate mb-2" }, [
                            _vm._v(_vm._s(item.product_name)),
                          ]),
                          _vm.removeItemEnable
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: _vm.$t(
                                        "marketing_place.remove_from_cart"
                                      ),
                                      delay: { show: 500, hide: 100 },
                                    },
                                    expression:
                                      "{\n                            content: $t('marketing_place.remove_from_cart'),\n                            delay: { show: 500, hide: 100 }\n                          }",
                                  },
                                ],
                                staticClass: "hover:text-danger",
                                attrs: {
                                  icon: "TrashIcon",
                                  svgClasses:
                                    "h-4 w-4 cursor-pointer text-danger",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeItemFromCart(item)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex items-center justify-between" },
                        [
                          _c(
                            "small",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.quantity > 1,
                                  expression: "item.quantity > 1",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("marketing_place.subtotal_value")
                                  ) +
                                  " (" +
                                  _vm._s(item.quantity) +
                                  " " +
                                  _vm._s(_vm.$t("marketing_place.items")) +
                                  ")"
                              ),
                            ]
                          ),
                          _c(
                            "small",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.quantity <= 1,
                                  expression: "item.quantity <=1",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("marketing_place.subtotal_value")
                                  ) +
                                  " (" +
                                  _vm._s(item.quantity) +
                                  " " +
                                  _vm._s(_vm.$t("marketing_place.item")) +
                                  ")"
                              ),
                            ]
                          ),
                          _c("small", { staticClass: "font-bold" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(item.quantity * item.price)
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c("vs-divider"),
                    ],
                    1
                  )
                }),
                _vm.cartItems.length
                  ? _c(
                      "div",
                      { staticClass: "flex items-center justify-end" },
                      [
                        _c("span", { staticClass: "text-md mr-3" }, [
                          _vm._v(
                            _vm._s(_vm.$t("marketing_place.total_value_")) + ":"
                          ),
                        ]),
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(_vm.formatValue(_vm.valueTotal))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("vs-divider"),
              ],
              2
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Rest from '../Rest'

export default class CartService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/carts'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getOpenCart(isCoporateSale: Boolean) {
    const params = {corporate_sale: isCoporateSale}
    return this.get('/open', params).then(data => {
      return data
    })
  }

  //To in specific cart
  getItems(cartId: Number) {
    return this.get(`/${cartId}/items`).then(data => {
      return data
    })
  }

  addCartItem(cartId: Number, data: any) {
    return this.post(`${cartId}/items`, data).then(
      (data) => {
        return data
      }
    )
  }
  //----------------------------------------------------
  //To open cart
  getItemsInOpenCart() {
    return this.get(`items`).then(data => {
      return data
    })
  }

  addCartItemInOpenCart(data: any) {
    return this.post(`items`, data).then(
      (data) => {
        return data
      }
    )
  }
  //----------------------------------------------------

  updateCartItem(cartId: Number, itemId: Number, data: any) {
    return this.put(`${cartId}/items/${itemId}`, data).then(
      (data) => {
        return data
      }
    )
  }

  deleteCartItem(cartId, itemId) {
    return this.delete(`/${cartId}/items/${itemId}`).then(
      (response) => {
        return response
      }
    )
  }

  addParticipants(cartId, data) {
    return this.post(`${cartId}/corporate_sales/users`, data).then(
      (data) => {
        return data
      }
    )
  }

  removeParticipant(cartId: Number, participantsIds) {
    return this.put(`${cartId}/corporate_sales/users/remove`,{
      participants: participantsIds
    })
  }

  payCart(cartId: Number, data: any) {
    return this.post(`${cartId}/pay`, data).then(
      (data) => {
        return data
      }
    )
  }

  getPaymentTypes() {
    return this.get(`/checkout/payment_types`).then(data => {
      return data
    })
  }

}

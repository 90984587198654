<template>
  <div>
    <div class="h-full">
      <div class="customizer-header mt-6 flex items-center justify-between px-6" v-if="isSideBar">
        <div>
          <h4>{{$t('marketing_place.purchase_summary')}}</h4>
          <!--<small>Customize & Preview in Real Time</small>-->
        </div>
        <feather-icon icon="XIcon" @click.stop="closeSideBar()"
                      class="cursor-pointer"
                      v-if="isSideBar"/>
      </div>

      <vs-divider class="mb-0" />

      <component :is="scrollbarTag" class="scroll-area--customizer pt-4 pb-6" :settings="settings">
        <div class="px-6">
          <div v-if="!cartItems.length" class="flex items-center justify-center pt-4">
            <p class="pb-4">{{$t('marketing_place.purchase_is_empty')}}</p>
          </div>
          <!-- Layout Type -->
          <div class="mt-4" v-for="item in cartItems" :key="item.id">
            <div class="flex items-start justify-between">
              <h5 class="truncate mb-2">{{item.product_name}}</h5>
              <feather-icon v-if="removeItemEnable"
                            icon="TrashIcon"
                            svgClasses="h-4 w-4 cursor-pointer text-danger"
                            class="hover:text-danger"
                            @click.stop="removeItemFromCart(item)"
                            v-tooltip="{
                              content: $t('marketing_place.remove_from_cart'),
                              delay: { show: 500, hide: 100 }
                            }"/>
            </div>
            <div class="flex items-center justify-between">
              <small v-show="item.quantity > 1"> {{$t('marketing_place.subtotal_value')}} ({{item.quantity}} {{$t('marketing_place.items')}})</small>
              <small v-show="item.quantity <=1"> {{$t('marketing_place.subtotal_value')}} ({{item.quantity}} {{$t('marketing_place.item')}})</small>
              <small class="font-bold">{{formatValue(item.quantity*item.price)}}</small>
            </div>
            <vs-divider />
          </div>
          <div class="flex items-center justify-end" v-if="cartItems.length">
            <span class="text-md mr-3">{{$t('marketing_place.total_value_')}}:</span><span class="font-bold">{{formatValue(valueTotal)}}</span>
          </div>
          <vs-divider />
        </div>
      </component>
    </div> <!-- end div class h-full -->
  </div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { currencyFormatter } from '@/util/Util'

import CartService from '@/services/api/CartService'

export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    cartItems: {type: Array, default: () => [], required: true},
    removeItemEnable: {type: Boolean, default: false, required: false},
    isSideBar: {type: Boolean, default: false, required: false},
    showTitle: {type: Boolean, default: true, required: false},
  },
  data () {
    return {
      settings: {
        maxScrollbarLength : 60,
        wheelSpeed         : .60
      },
      service: null
    }
  },
  watch: {

  },
  computed: {
    valueTotal () {
        let total = 0
       this.cartItems.forEach((item) => {
            total += (item.quantity*item.price)
        });
        return total
    },
    hideScrollToTopLocal: {
      get ()    { return this.hideScrollToTop },
      set (val) { this.$emit('toggleHideScrollToTop', val) }
    },
    scrollbarTag () { return this.$store.state.is_touch_device ? 'div' : 'VuePerfectScrollbar' },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    removeItemFromCart (item) {
      this.$vs.loading()
      const cartId = item.cart_id
      const itemId = item.id
      this.service.deleteCartItem(cartId, itemId).then(
        data => {
          this.updateCartHandling(data)
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(() => { this.$vs.loading.close() })

    },
    closeSideBar(){
      this.$emit('closeSideBar')
    },
    updateCartHandling(item){
      this.$emit('updateCartHandling', item)
    },
    formatValue(value){
      return currencyFormatter(value)
    },
  },
  beforeMount() {
    this.service = CartService.build(this.$vs)
  },
}

</script>


<style lang="scss">
#cart-resume {
  .vs-sidebar {
    position: fixed;
    z-index: 52000;
    width: 400px;
    max-width: 90vw;
    // @apply shadow-lg;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
  }
}

.customizer-btn {
  position: fixed;
  top: 30%;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 50000;

  .vs-icon {
    animation: 1.5s ease-in-out infinite;
  }
}

.scroll-area--customizer {
  height: calc(100% - 5rem);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>

